<template>
  <section>
    <p class="text-h5 secondary--text">Administración de regiones</p>
    <v-row>
      <v-col cols="12" sm="12" md="2">
        <v-btn @click="showDialog = true" dark block color="secondary">
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="4" lg="4">
        <v-text-field outlined label="Buscar" clearable @keyup="filterName" v-model="buscador"
                      @click:clear="(buscador = null), getRegiones()"/>
      </v-col>
    </v-row>
    <p class="secondary--text ">Listado de regiones</p>
    <data-table-component v-models:select="perPage" v-models:pagina="page" :headers="headers" :items="items"
                          @paginar="paginate" :total_registros="totalRows">
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="editarRegion(item)" v-bind="attrs" v-on="on" large color="primary">
              mdi-pencil
            </v-icon>
          </template>

          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" large color="primary"
                    @click="(deleteDialog = true), (regionSeleccionada = item.id)">
              mdi-trash-can
            </v-icon>
          </template>
        </v-tooltip>
      </template>
      <template v-slot:[`item.activo`]="{ item }">
        <v-chip :class="item.activo ? 'secondary ' : ''" :style="item.activo ? 'color:white !important' : ''">
          {{ item.activo ? "Activo" : "Inactivo" }}
        </v-chip>
      </template>
    </data-table-component>
    <v-dialog persistent max-width="750" class="modal" v-model="showDialog">
      <v-card max-width="750">
        <v-btn @click="clearInfo" icon color="secondary">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
        <v-card-text>
          <section>
            <v-row>
              <p class="text-h5 secondary--text mx-4">
                {{ editable ? "Editar" : "Agregar" }} Región
              </p>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field 
                  label="Nombre *" 
                  outlined 
                  v-model="region.nombre" 
                  @input="$v.region.nombre.$touch()"
                  :error-messages="nombreErrors"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-checkbox 
                  label="Activo" 
                  outlined 
                  v-model="region.activo"
                  :error-messages="activoErrors"
                />
              </v-col>
            </v-row>
          </section>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" class="text-capitalize mx-8 mb-2 mb-sm-0" outlined @click="clearInfo()">
            Cancelar
          </v-btn>
          <v-btn color="secondary" @click="guardarRegion" class="white--text mx-8 px-8 text-capitalize mt-2 mt-sm-0">
            {{ editable ? "Editar" : "Agregar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialogoConfirmacion :show="deleteDialog" title="¿Deseas remover esta región?"
                         message="De confirmarse la siguiente acción, los cambios no serán reversibles."
                         btnConfirmar="Aceptar"
                         @close="(deleteDialog = false), (atributoSeleccionado = null)" @confirm="eliminarRegion()"/>
  </section>
</template>
<script>
import DataTableComponent from "../../components/DataTableComponent.vue";
import dialogoConfirmacion from "../../components/ConfirmationDialogComponent.vue";
import { helpers, required } from "vuelidate/lib/validators";
import { Validator } from '@/utils/form-validation.js';

const txtField = helpers.regex(
    "txtArea",
    /^[A-Za-z0-9-&.,:;()À-ÿ\u00f1\u00d1 ]*$/
);
const boolean = ''
export default {
  name: "AdministracionRegiones",
  components: {
    DataTableComponent,
    dialogoConfirmacion,
  },
  data: () => ({
    headers: [
      {
        text: "Nombre",
        value: "nombre",
      },
      {
        text: "Activo",
        value: "activo"
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    items: [],
    deleteDialog: false,
    regionSeleccionada: null,
    totalRows: 0,
    page: 1,
    perPage: 10,
    showDialog: false,
    region: {
      id: null,
      nombre: null,
      activo: true
    },
    editable: false,
    buscador: null,
    timer: null,
  }),
  validations: {
    region: {
      nombre: {required, txtField},
      activo: {required,}
    },
  },
  computed: {
    nombreErrors() {
      return new Validator(this.$v.region.nombre).detect().getResult();
    },
    activoErrors() {
      return new Validator(this.$v.region.activo).detect().getResult();
    },
  },
  methods: {
    filterName() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        await this.getRegiones();
      }, 500);
    },
    async getRegiones() {
      const {status, data, headers} =
          await this.services.cmRegiones.listRegion({
            page: this.page,
            per_page: this.perPage,
            busqueda: this.buscador,
          });

      if (status == 200) {
        this.items = data.data;
        this.totalRows = Number(headers.total_rows);
      }
    },
    paginate(params) {
      const {cantidad_por_pagina, pagina} = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;

      this.getRegiones();
    },
    clearInfo() {
      this.region.id = null;
      this.region.nombre = null;
      this.showDialog = false;
      this.$v.$reset();
      this.editable = false;
    },
    async eliminarRegion() {
      const {status, data} = await this.services.cmRegiones.deleteRegion(this.regionSeleccionada)
      const {message} = data;
      if (status === 200) {
        this.grupoSeleccionada = null;
        this.deleteDialog = false;
        await this.getRegiones();
        this.temporalAlert({
          show: true,
          message,
          type: "success",
        });
        return
      }
      this.temporalAlert({
        show: true,
        message,
        type: "error",
      });
    },
    async guardarRegion() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let response = null;

        if (this.editable) {
          response = await this.services.cmRegiones.updateRegion(
              this.region.id,
              this.region
          );
        } else {
          response =
              await this.services.cmRegiones.storeRegion(
                  this.region
              );
        }

        if (response.status == 201 || response.status == 200) {
          this.temporalAlert({
            show: true,
            message: `Región ${this.editable ? "editada" : "guardada"
            } con éxito`,
            type: "success",
          });
          this.clearInfo();
          this.getRegiones();
        }
      }
    },
    editarRegion(item) {
      this.region.id = item.id;
      this.region.nombre = item.nombre;
      this.region.activo = item.activo;

      this.editable = true;
      this.showDialog = true;
    },
  },
  created() {
    this.getRegiones();
  },
};
</script>
